import PropTypes from "prop-types";

const errorType = PropTypes.shape({ message: PropTypes.string });

const optionType = PropTypes.shape({
  id: PropTypes.string,
  title: PropTypes.string,
});

const childrenType = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.node),
  PropTypes.node,
]);

const slugType = PropTypes.shape({
  current: PropTypes.string,
});

const categoryType = PropTypes.shape({
  description: PropTypes.arrayOf(PropTypes.object),
  id: PropTypes.string,
  title: PropTypes.string,
});

const themeType = PropTypes.shape({
  id: PropTypes.string,
  description: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
});

const videoType = PropTypes.shape({
  sanityId: PropTypes.string,
  host: PropTypes.string,
  source: PropTypes.string,
  thumbnail: PropTypes.shape({
    url: PropTypes.string,
    key: PropTypes.string,
  }),
});

const imageType = PropTypes.shape({
  alt: PropTypes.string,
  asset: PropTypes.shape({
    url: PropTypes.string,
  }),
});

const fileType = PropTypes.shape({
  asset: PropTypes.shape({
    url: PropTypes.string,
  }),
});

const authorType = PropTypes.shape({
  id: PropTypes.string,
  bio: PropTypes.arrayOf(PropTypes.object),
  image: imageType,
  name: PropTypes.string,
  slug: slugType,
});

const mediaType = PropTypes.shape({
  _rawBackstory: PropTypes.arrayOf(PropTypes.object),
  authors: PropTypes.arrayOf(authorType),
  category: categoryType,
  excerpt: PropTypes.arrayOf(PropTypes.object),
  _rawExcerpt: PropTypes.arrayOf(PropTypes.object),
  file: fileType,
  id: PropTypes.string,
  image: imageType,
  inview: PropTypes.arrayOf(PropTypes.object),
  _rawInview: PropTypes.arrayOf(PropTypes.object),
  publishedAt: PropTypes.string,
  slug: slugType,
  themes: PropTypes.arrayOf(themeType),
  title: PropTypes.string,
  video: videoType,
  _rawVideo: videoType,
});

const albumType = PropTypes.shape({
  children: childrenType,
  description: PropTypes.string,
  id: PropTypes.string,
  media: PropTypes.arrayOf(mediaType),
  publishedAt: PropTypes.string,
  slug: slugType,
  title: PropTypes.string,
});

const featuredMediaType = PropTypes.shape({
  id: PropTypes.string,
  image: imageType,
  media: mediaType,
});

const sieveType = PropTypes.shape({
  filter: PropTypes.shape({
    title: PropTypes.string,
    category: PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
    }),
    theme: PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
    }),
  }),
  sort: PropTypes.shape({
    prop: PropTypes.string,
    dir: PropTypes.oneOf(["DESC", "ASC"]),
  }),
});

export {
  errorType,
  slugType,
  categoryType,
  themeType,
  videoType,
  mediaType,
  albumType,
  childrenType,
  optionType,
  featuredMediaType,
  sieveType,
};
