import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";

const darkTheme = createMuiTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#54AABA",
    },
    secondary: {
      main: "#7CEA9C",
    },
    background: {
      default: "#33333d",
      paper: "#373740",
      dark: "#27272f",
      black: "#1e1e1e",
    },
  },
  typography: {
    fontFamily: [
      '"Roboto Condensed"',
      "-apple-system",
      "BlinkMacSystemFont",
      "sans-serif",
    ].join(","),
    h3: {
      fontFamily: ["Eczar", "serif"],
    },
    h6: {
      fontWeight: 700,
    },
    subtitle2: {
      fontWeight: 700,
    },
    body1: {
      fontFamily: ["Eczar", "serif"],
    },
    body2: {
      fontWeight: 300,
    },
    button: {
      fontWeight: 700,
    },
  },
});

const theme = responsiveFontSizes(darkTheme);

export default theme;
