import React from "react";
import { Link as GatsbyLink } from "gatsby";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Logo from "../Logo/Logo";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1, 0),
  },
  wrapper: {
    flexWrap: "wrap",
    [theme.breakpoints.up("sm")]: {
      flexWrap: "nowrap",
    },
  },
  logo: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      marginRight: theme.spacing(2),
    },
  },
  nav: {
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "450px",
      flexShrink: 1,
      display: "flex",
      justifyContent: "flex-end",
    },
  },
  navList: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    listStyle: "none",
    padding: 0,
    margin: 0,
    [theme.breakpoints.up("sm")]: {
      maxWidth: "380px",
      flexShrink: 1,
    },
  },
  navListItem: {
    display: "inline-block",
  },
  link: {
    display: "inline-block",
    padding: theme.spacing(0, 1),
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  linkActive: {
    borderBottom: `3px solid ${theme.palette.primary.main}`,
  },
}));

const siteNav = [
  {
    name: "home",
    title: "Home",
    path: "/",
  },
  {
    name: "media",
    title: "Media",
    path: "/media/",
  },
  {
    name: "albums",
    title: "Albums",
    path: "/album/",
  },
  {
    name: "search",
    title: "Search",
    path: "/media/#mediaSearch",
  },
  {
    name: "about",
    title: "About",
    path: "/about/",
  },
];

const Header = () => {
  const classes = useStyles();

  const createNavListItem = (link) => (
    <li key={link.name} className={classes.navListItem}>
      <Link
        underline="none"
        className={classes.link}
        color="textPrimary"
        variant="h6"
        component={GatsbyLink}
        activeClassName={classes.linkActive}
        partiallyActive={link.name !== "home"}
        to={link.path}
      >
        {link.title}
      </Link>
    </li>
  );

  const navListItems = siteNav.map(createNavListItem);

  return (
    <AppBar
      className={classes.root}
      color="transparent"
      elevation={0}
      position="relative"
    >
      <Container>
        <Toolbar disableGutters className={classes.wrapper}>
          <Logo className={classes.logo} />
          <nav className={classes.nav}>
            <ul className={classes.navList}>{navListItems}</ul>
          </nav>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
