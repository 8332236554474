import { graphql, StaticQuery } from "gatsby";
import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider, makeStyles } from "@material-ui/core/styles";
import { Helmet } from "react-helmet";
import darkTheme from "../../theme";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { childrenType } from "../../types";

const query = graphql`
  query SiteTitleQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
    }
  }
`;

const HEADER_PLUS_FOOTER_HEIGHT = 203;

const useStyles = makeStyles(() => ({
  main: {
    minHeight: `calc(100vh - ${HEADER_PLUS_FOOTER_HEIGHT}px)`,
  },
}));

function Layout({ children }) {
  const classes = useStyles();
  return (
    <StaticQuery
      query={query}
      render={(data) => {
        if (!data.site) {
          throw new Error(
            'Missing "Site settings". Open the Studio at http://localhost:3333 and some content in "Site settings"'
          );
        }
        return (
          <>
            <Helmet>
              <link rel="preconnect" href="https://fonts.gstatic.com" />
              <link
                href="https://fonts.googleapis.com/css2?family=Eczar&family=Roboto+Condensed:wght@300;400;700&display=swap"
                rel="stylesheet"
              />
              <link
                rel="icon"
                type="image/png"
                sizes="32x32"
                href="/favicon-32x32.png"
              />
              <link
                rel="icon"
                type="image/png"
                sizes="32x32"
                href="/favicon-16x16.png"
              />
            </Helmet>
            <ThemeProvider theme={darkTheme}>
              <CssBaseline />
              <Header />
              <main className={classes.main}>{children}</main>
              <Footer />
            </ThemeProvider>
          </>
        );
      }}
    />
  );
}

Layout.propTypes = { children: childrenType.isRequired };

export default Layout;
