import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  footer: {
    padding: theme.spacing(4),
    textAlign: "center",
    backgroundColor: theme.palette.background.dark,
  },
}));

const Footer = () => {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <Typography variant="caption">
        &#169; {new Date().getFullYear()} InView Media, All Rights Reserved
      </Typography>
    </footer>
  );
};

export default Footer;
