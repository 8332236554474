import React from "react";
import PropTypes from "prop-types";
import logo from "../../images/logo-main.png";

const Logo = ({ className }) => {
  return <img className={className} src={logo} alt="Logo" />;
};

Logo.defaultProps = {
  className: "",
};

Logo.propTypes = {
  className: PropTypes.string,
};

export default Logo;
