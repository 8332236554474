import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import { childrenType } from "../../types";

const useStyles = makeStyles((theme) => ({
  section: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(8),
    marginBottom: theme.spacing(8),
  },
  default: {
    backgroundColor: theme.palette.background.default,
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
  },
  dark: {
    backgroundColor: theme.palette.background.dark,
  },
  black: {
    backgroundColor: theme.palette.background.black,
  },
}));

function Section({
  heading,
  subheading,
  children,
  fullWidth,
  background,
  isArticle,
  id,
}) {
  const classes = useStyles();
  return (
    <section id={id} className={`${classes.section} ${classes[background]}`}>
      <Container
        component={isArticle ? "article" : "div"}
        maxWidth={fullWidth ? false : "lg"}
      >
        {subheading && (
          <Typography variant="h3" component="span">
            {subheading}
          </Typography>
        )}
        {heading && (
          <Typography
            gutterBottom
            variant={subheading ? "h1" : "h2"}
            component={isArticle ? "h1" : "h2"}
          >
            {heading}
          </Typography>
        )}

        {children}
      </Container>
    </section>
  );
}

Section.defaultProps = {
  heading: "",
  subheading: "",
  fullWidth: false,
  background: "default",
  isArticle: false,
  id: null,
};

Section.propTypes = {
  heading: PropTypes.oneOfType([PropTypes.string, childrenType]),
  subheading: PropTypes.string,
  children: childrenType.isRequired,
  isArticle: PropTypes.bool,
  fullWidth: PropTypes.bool,
  background: PropTypes.oneOf(["default", "paper", "dark", "black"]),
  id: PropTypes.string,
};

export default Section;
